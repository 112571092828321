<template>
  <main id="content" role="main">
    <!-- ========== Hero Section ========== -->
    <div id="recap-hero-section" class="position-relative z-index-2 bg-img-hero overflow-hidden" :class="apidaysData && apidaysData.hero_bg_class ? apidaysData.hero_bg_class : ''">
      <!-- Breadcrumb -->
      <div class="container mt-md-6 pl-0">
        <div class="row justify-content-sm-between mb-5">
          <div class="col-md">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb mb-md-3 mb-0">
                <li class="breadcrumb-item">
                  <router-link :to="{ name: 'home', params: { lang: $route.params.lang }}">{{ $t('CC01-03-08') }}</router-link>
                </li>
                <li class="breadcrumb-item" aria-current="page">
                  <router-link :to="{ name: 'community-updates-overview', params: { lang: $route.params.lang }}">{{ $t('M01b-03') }}</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page"><router-link :to="overviewUrl">{{ $t(apidaysData.event_title) }}</router-link></li>
                <li class="breadcrumb-item active" aria-current="page"><a class="disabled">{{ $t(apidaysData.recap.recap_heading) }} {{ $t('M01cii-02-recap') }}</a></li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <!-- Title -->
      <div class="container space-bottom-2 position-relative">
        <div class="w-lg-60 text-center mx-lg-auto">
          <div class="mb-5">
            <img class="img-fluid mb-5 max-w-19rem" src="/assets/events/apidays-live-hongkong/organisers/apidays-live-hk-color.png">
            <h1 class="mb-3">{{ $t(apidaysData.recap.recap_heading) }} Recap</h1>
            <p class="lead">{{ $t(apidaysData.recap.recap_description) }}</p>
          </div>
        </div>
      </div>
    </div>
    <!-- ========== End Hero Section ========== -->

    <div class="background-container">
      <div class="container space-2">
        <input v-for="item in category_list" :id="item.category" :key="item.category" v-model="selected_category" type="radio" name="categories" :value="item.category">
        <ol class="filters-btn label-list">
          <li v-for="item in category_list" :key="item.category">
            <label class="btn btn-sm btn-soft-secondary btn-pill py-2 px-3 m-1 mb-2 category-filter" :class="{'selectedCategory': selected_category === item.category}" :for="item.category">{{ item.category_title }}</label>
          </li>
        </ol>
        <ol class="recap-card-list">
          <div class="row">
            <li v-for="item in filtered_category_list" :key="item.url" class="col-md-6 col-lg-4 mb-5 filters-item" :data-category="item.category">
              <ApidaysRecapCard :item="item" />
            </li>
          </div>
        </ol>
      </div>
    </div>

  </main>
</template>

<script>
import { mapGetters } from 'vuex'
import { formatName } from '@/utils/format-util'
import { getYYYYMMDD } from '@/utils/date-time-util'
import ApidaysRecapCard from '@/components/apidays-recap-card'

export default {
  name: 'ApidaysRecap',
  components: {
    ApidaysRecapCard
  },
  data() {
    return {
      category_list: [],
      selected_category: 'All'
    }
  },
  computed: {
    ...mapGetters([
      'apidaysData', 'language'
    ]),
    apidays_recap_list() {
      if (this.apidaysData && this.apidaysData.recap) {
        return this.apidaysData.recap.recap_list
      } else return []
    },
    filtered_category_list() {
      if (this.apidays_recap_list.length === 0) return []
      if (this.selected_category === 'All') return this.apidays_recap_list
      else return this.apidays_recap_list.filter(item => item.category === this.selected_category)
    },
    overviewUrl() {
      var formattedStartDate = ''
      if (this.apidaysData.startTime && getYYYYMMDD(this.apidaysData.startTime)) {
        formattedStartDate = getYYYYMMDD(this.apidaysData.startTime)
      } else if (this.apidaysData.date && getYYYYMMDD(event.compareTime)) {
        formattedStartDate = getYYYYMMDD(this.apidaysData.compareTime)
      } else {
        formattedStartDate = getYYYYMMDD(this.apidaysData.date)
      }
      return this.apidaysData ? `/${this.$route.params.lang}/community-updates/${formattedStartDate}-${formatName(this.apidaysData.title)}` : ''
    }
  },
  watch: {
    apidays_recap_list() {
      this.getCategoryList(this.apidays_recap_list)
    }
  },
  beforeDestroy() {
    window.document.styleSheets[0].deleteRule(window.document.styleSheets[0].cssRules.length - 1)
  },
  created() {
    this.getCategoryList(this.apidays_recap_list)
  },
  mounted() {
    var sheet = window.document.styleSheets[0]
    sheet.insertRule(
      `#recap-hero-section {
        background-image: url(/assets/${this.apidaysData.hero_bg_image_path})
      }`,
      sheet.cssRules.length
    )
  },
  methods: {
    getCategoryList(list) {
      function compare(a, b) {
        // Use toUpperCase() to ignore character casing
        const titleA = a.category_title.toUpperCase()
        const titleB = b.category_title.toUpperCase()

        let comparison = 0
        if (titleA > titleB) {
          comparison = 1
        } else if (titleA < titleB) {
          comparison = -1
        }
        return comparison
      }
      let array = []
      const temp = []
      for (let i = 0; i < list.length; i++) {
        const object = {
          category_title: '',
          category: ''
        }
        object.category_title = list[i].category_title
        object.category = list[i].category
        array = array.concat(object)
      }
      // const map = new Map()
      // for (const item of array) {
      //   if (!map.has(item.category_title)) {
      //     map.set(item.category_title, true)
      //     temp.push({
      //       category_title: item.category_title,
      //       category: item.category
      //     })
      //   }
      // }
      temp.sort(compare)
      this.category_list = [{ category_title: 'All', category: 'All' }]
      this.category_list = this.category_list.concat(temp)
    }
  }
}
</script>

<style lang="scss" scoped>
.selectedCategory, .category-filter:hover {
  background: #1c344c !important;
  color: #fff !important;
}
.background-container {
  background-color: rgb(249,254,255)
}
.label-list {
  margin-left: 1rem;
}
.recap-card-list {
  padding: 0 1.5rem 1.5rem 1.5rem;
}
</style>
