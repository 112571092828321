<template>
  <div>
    <template v-if="type == 'empty'">
      <div class="empty">
        <div class="empty-pic">
          <img :src="require(`@/assets/svg/illustrations/loading.svg`)">
        </div>
        <p>Loading...</p>
      </div>
    </template>
    <template v-else>
      <div
        v-for="line in lines"
        :key="line"
        class="skeleton"
        :class="[
          {'last-line': lines !== 1 && lines === line - 1},
          {'animated-loading': animated },
          {'rounded-corners': roundedCorners },
          type
        ]"
      />
    </template>
  </div>
</template>

<script>
export default {
  name: 'SkeletonUI',
  props: {
    type: {
      type: String,
      default: 'p'
    },
    lines: {
      type: Number,
      default: 1
    },
    animated: {
      type: Boolean,
      default: true
    },
    roundedCorners: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="scss" scoped>
$color-1: #F5F7FA;
$color-2: #ECEDEE;
$loading-speed: 1.4s; // define in seconds
$rounded-corner-radius: 5px;

.animated-loading {
  animation: loading $loading-speed ease infinite;
}
.rounded-corners {
  border-radius: 5px;
}
.skeleton {
  background: linear-gradient(90deg, $color-1 25%, $color-2 37%,$color-1 63%);
  background-size: 400% 100%;
  min-height: 16px;
  &.h2 {
    height: calc((1.3125rem + 0.75vw) * 1.4);
  }
  &.h4 {
    height: 1.4em;
  }
  &.span, &.p {
    height: 25.6px;
  }
  &.img {
    height: 100%;
    &.cover-image {
      width: 100%;
      height: auto;
      max-width: 100%;
      min-height: 250px;
      aspect-ratio: 24 / 5;
      border-radius: 0px;
      margin: auto;
    }
  }
  &.button {
    width: 125px;
    height: 29.7812px;
  }
  &.p + &.p, &.span + &.span {
    margin-top: 8px;
  }
  &.last-line {
    width: 75%;
  }
}
.empty {
  text-align: center;
  min-height: 50vh;
  .empty-pic {
    width: 300px;
    height: auto;
    display: block;
    margin: 48px auto 24px auto;
    & + p {
      width: 100%;
    }
  }
}

@keyframes loading {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}
</style>
