<template>
  <div :id="id" class="video-player">
    <img class="img-fluid video-player-preview" :src="`/assets/${coverImage}`">
    <a
      class="js-inline-video-player video-player-btn video-player-centered"
      :data-hs-video-player-options="JSON.stringify(options)"
    >
      <span id="ga-event-apidays-2021-opening-video-play-button" class="video-player-icon video-player-icon-primary">
        <i class="fas fa-play" />
      </span>
    </a>
    <div class="embed-responsive embed-responsive-16by9">
      <div id="VideoIframe" />
    </div>
  </div>
</template>

<script>
const $ = require('jquery')
import HSVideoPlayer from '@/assets/vendor/hs-video-player/src/js/hs-video-player'

export default {
  name: 'VideoSection',
  props: {
    id: {
      type: String,
      default: 'VideoPlayer'
    },
    iframeId: {
      type: String,
      default: 'VideoIframe'
    },
    coverImage: {
      type: String,
      default: ''
    },
    options: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    options: {
      deep: true,
      handler() {
        $('.js-inline-video-player').each(function() {
          new HSVideoPlayer($(this)).init()
        })
      }
    }
  },
  mounted() {
    $('.js-inline-video-player').each(function() {
      new HSVideoPlayer($(this)).init()
    })
  }
}
</script>

<style lang="scss" scoped>
.video-player {
  background-color: #F7FAFF;
}
</style>
