<template>
  <div>
    <ErrorPage v-if="error404occured" />
    <div v-else>
      <template v-if="loading">
        <SkeletonUI type="empty" />
      </template>
      <template v-else>
        <template v-if="eventInfo && eventInfo.protectedContentTemplate">
          <component :is="eventInfo.protectedContentTemplate" />
        </template>
        <template v-else>
          <main v-cloak id="content" role="main" class="overflow-hidden">

            <!-- ========== Breadcrumb ========== -->
            <div class="container space-lg-1 pl-0">
              <div aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link :to="{ name: 'home', params: { lang: $route.params.lang }}">{{ $t('CC01-03-08') }}</router-link>
                  </li>
                  <li class="breadcrumb-item" aria-current="page">
                    <router-link :to="{ name: 'community-updates-overview', params: { lang: $route.params.lang }}">{{ $t('M01b-03') }}</router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    <router-link :to="eventPath">{{ eventInfo.title }}</router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page"><a class="disabled">{{ $t('M01cii-02-resources') }}</a></li>
                </ol>
              </div>
            </div>
            <!-- ========== End Breadcrumb ========== -->

            <!-- Event Title -->
            <div class="container space-top-1">
              <div class="w-lg-80 mx-auto">
                <h2>{{ eventInfo.title }}</h2>
              </div>
            </div>

            <!-- SVG Top Shape -->
            <figure>
              <svg
                preserveAspectRatio="none"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                width="100%"
                height="96px"
                viewBox="0 0 1921 273"
                style="margin-bottom: -8px; enable-background:new 0 0 1921 273;"
                xml:space="preserve"
              >
                <polygon fill="#f9fbff" points="0,273 1921,273 1921,0 " />
              </svg>
            </figure>

            <div class="bg-light position-relative">
              <div class="container space-bottom-2 space-bottom-md-3">
                <div class="w-lg-80 mx-auto">
                  <!-- Video -->
                  <div v-show="eventInfo.more_card && eventInfo.more_card[0] && eventInfo.more_card[0].video_id" class="row justify-content-center mb-11">
                    <div class="col-12 mt-n11">
                      <div class="card p-4">
                        <VideoSection
                          id="VideoPlayer"
                          class="video-player bg-dark mb-4"
                          :coverImage="`/events/webinars/post-event-resources/${eventInfo.more_card[0].video_cover_image}`"
                          :options="{
                            videoId: eventInfo.more_card[0].video_id,
                            parentSelector: '#VideoPlayer',
                            targetSelector: '#VideoIframe',
                            isAutoplay: true,
                            isMuted: false,
                            classMap: {
                              toggle: 'video-player-played'
                            }
                          }"
                        />
                        <p v-for="item in eventInfo.about" :key="item">{{ item }}</p>
                        <div v-if="eventInfo.about_special" v-html="eventInfo.about_special" />
                      </div>
                    </div>
                  </div>

                  <h2 v-if="eventInfo.more_card && eventInfo.more_card[0] && eventInfo.more_card[0].ppt_url" class="border-bottom pb-3">{{ $t('M01cii-06') }}</h2>

                  <!-- Loop Other Resources -->
                  <div v-if="eventInfo.more_card && eventInfo.more_card[0] && eventInfo.more_card[0].ppt_url" class="mt-6">
                    <h3>{{ $t('M01cii-07') }}</h3>
                    <p><span v-html="$t('M01cii-08-01')" /><span class="font-weight-bold text-primary">{{ $t('M01cii-08-02') }}</span></p>
                    <a id="ga-webinar-download-slide-button" class="btn btn-primary btn-wide mb-1" :href="`/assets/events/webinars/post-event-resources/${eventInfo.more_card && eventInfo.more_card[0] && eventInfo.more_card[0].ppt_url ? eventInfo.more_card[0].ppt_url : ''}`" download>
                      {{ $t('M01cii-09') }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
const $ = require('jquery')
import ErrorPage from '@/views/error-page/index'
import { mapGetters } from 'vuex'
import { formatName } from '@/utils/format-util'
import { getYYYYMMDD } from '@/utils/date-time-util'
import HSGoTo from '@/assets/vendor/hs-go-to/src/js/hs-go-to'
import ApidaysRecap from './apidays-recap'
import VideoSection from '../components/video-section'
import SkeletonUI from '@/components/skeleton-ui'

export default {
  name: 'ProtectedContent',
  components: {
    ErrorPage,
    ApidaysRecap,
    VideoSection,
    SkeletonUI
  },
  data() {
    return {
      eventTitle: '',
      eventInfo: {},
      error404occured: false,
      loading: false
    }
  },
  computed: {
    ...mapGetters([
      'events'
    ]),
    eventPath() {
      var formattedName = this.eventInfo.title_eng ? formatName(this.eventInfo.title_eng) : formatName(this.eventInfo.title)
      var formattedStartDate = ''
      if (this.eventInfo.startTime && getYYYYMMDD(this.eventInfo.startTime)) {
        formattedStartDate = getYYYYMMDD(this.eventInfo.startTime)
      } else if (this.eventInfo.compareTime && getYYYYMMDD(this.eventInfo.compareTime)) {
        formattedStartDate = getYYYYMMDD(this.eventInfo.compareTime)
      } else {
        formattedStartDate = getYYYYMMDD(this.eventInfo.date)
      }
      var path = formattedStartDate ? `${formattedStartDate}-${formattedName}` : `${formattedName}`
      // return path[path.length - 1] === '-' ? path.substr(0, path.length - 1) : path
      return `/${this.$route.params.lang}/community-updates/${path}`
    }
  },
  watch: {
    async events() {
      if (this.events.length === 0) {
        await this.$store.dispatch('events/getEventList')
      }
    },
    async $route() {
      this.error404occured = false
      this.getEventTitle()
      await this.getEventInfo()
    }
  },
  async created() {
    this.error404occured = false
    this.getEventTitle()
    await this.getEventInfo()
  },
  mounted() {
    new HSGoTo($('.js-go-to')).init()
  },
  methods: {
    getEventTitle() {
      this.event_title = this.$route.params.eventTitle
    },
    async getEventInfo() {
      this.loading = true
      const event_title = this.event_title
      if (this.events.length === 0) {
        await this.$store.dispatch('events/getEventList')
      }
      const filteredEvents = this.events.filter((event) => {
        var formattedName = event.title_eng ? formatName(event.title_eng) : formatName(event.title)
        var formattedStartDate = ''
        if (event.startTime && getYYYYMMDD(event.startTime)) {
          formattedStartDate = getYYYYMMDD(event.startTime)
        } else if (event.date && getYYYYMMDD(event.compareTime)) {
          formattedStartDate = getYYYYMMDD(event.compareTime)
        } else {
          formattedStartDate = getYYYYMMDD(event.date)
        }
        var title = formattedStartDate ? `${formattedStartDate}-${formattedName}` : `${formattedName}`
        title[title.length - 1] === '-' ? title = title.substr(0, title.length - 1) : null
        return title === event_title
      })
      if (filteredEvents.length > 0) {
        this.eventInfo = filteredEvents[0]
        //  Changed on Jan 27 - Removed title for static i18n
        //  document.title = `Resource of ${this.eventInfo.title} | ${this.site_info[this.$i18n.locale].name}`
        if (this.eventInfo.protectedContentTemplate === 'ApidaysRecap' && this.apidaysTitle !== this.eventInfo.title) {
          await this.$store.dispatch('apidays/getEventData', this.eventInfo.title)
        }
        this.loading = false
      } else {
        this.error404occured = true
        this.loading = false
      }
    }
  }
}
</script>
