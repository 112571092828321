<template>
  <main class="content" role="main">

    <div class="container space-top-2 space-bottom-3 overflow-hidden">
      <div class="w-lg-80 mx-lg-auto text-center">
        <img class="img-fluid w-100 w-md-50" src="@/assets/svg/illustrations/oah_404.svg" alt="404 image">
        <div class="my-6">
          <h2>{{ $t('M10-03') }}</h2>
          <p>{{ $t(description) }}</p>
        </div>
        <router-link :to="{ name: 'home', params: { lang: $route.params.lang }}" class="btn btn-primary">{{ $t('M10-05') }}</router-link>
      </div>
    </div>

  </main>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ErrorPage',
  props: {
    description: {
      type: String,
      default: 'M10-04a'
    },
    title: {
      type: String,
      default: 'Page Not Found'
    }
  },
  computed: {
    ...mapGetters([
      'site_info'
    ])
  },
  watch: {
    '$i18n.locale'() {
      this.changeTabName()
    }
  },
  created() {
    this.changeTabName()
    this.$nextTick(() => {
      this.$store.dispatch('app/setLoading', false)
    })
  },
  methods: {
    changeTabName() {
      document.title = `${this.title} | ${this.site_info[this.$i18n.locale].name}}`
    }
  }
}
</script>
